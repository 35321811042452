import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {CustomLayoutComponent} from './custom-layout/custom-layout.component';
import {pageResolver} from './page/page.resolver';
import {AuthGuard} from './guards/auth.guard';
import {DashboardComponent} from "./pages/dashboard/dashboard.component";

const routes: Routes = [
  {
    path: 'auth',
    children: [
      {
        path: 'login',
        loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginModule)
      }
    ]
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: CustomLayoutComponent,
    children: [
      {
        path: '',
        component: DashboardComponent
      },
      {
        path: 'events',
        children: [
          {
            path: 'list',
            loadChildren: () => import('./pages/events/list/list.module').then(m => m.ListModule)
          }
        ]
      },
      {
        path: 'users',
        children: [
          {
            path: 'list',
            loadChildren: () => import('./pages/users/list/list.module').then(m => m.ListModule)
          }
        ]
      },
      {
        path: 'menus',
        children: [
          {
            path: 'list',
            loadChildren: () => import('./pages/menus/list/list.module').then(m => m.ListModule)
          }
        ]
      },
      {
        path: 'event-categories',
        children: [
          {
            path: 'list',
            loadChildren: () => import('./pages/event-categories/list/list.module').then(m => m.ListModule)
          }
        ]
      },
      {
        path: 'blog',
        children: [
          {
            path: 'list',
            loadChildren: () => import('./pages/blog/list/list.module').then(m => m.ListModule)
          }
        ]
      },
      {
        path: 'blog-categories',
        children: [
          {
            path: 'list',
            loadChildren: () => import('./pages/blog-categories/list/list.module').then(m => m.ListModule)
          }
        ]
      },
      {
        path: 'social',
        children: [
          {
            path: 'list',
            loadChildren: () => import('./pages/social/list/list.module').then(m => m.ListModule)
          }
        ]
      },
      {
        path: 'site-options',
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/site-options/list/list.module').then(m => m.ListModule)
          }
        ]
      },
      {
        path: 'files',
        children: [
          {
            path: 'file-manager',
            loadChildren: () => import('./pages/files/file-manager/file-manager.module').then(m => m.FileManagerModule)
          }
        ]
      },
      {
        path: 'pages',
        children: [
          {
            path: 'list',
            loadChildren: () => import('./pages/pages/list/list.module').then(m => m.ListModule),
          },
          {
            path: 'list/:folderId',
            loadChildren: () => import('./pages/pages/list/list.module').then(m => m.ListModule)
          },
          {
            path: 'builder/create',
            loadChildren: () => import('./pages/pages/builder/builder.module').then(m => m.BuilderModule)
          },
          {
            path: 'builder/:id',
            loadChildren: () => import('./pages/pages/builder/builder.module').then(m => m.BuilderModule),
            resolve: {
              page: pageResolver,
            }
          }
        ]
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
