import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Page} from "./types/page";
import {Observable, Subject} from "rxjs";
import {PagesListResponse} from './types/pages-list-response';

@Injectable({
  providedIn: 'root'
})
export class PageService {
  public currentPage$: Observable<Page>;

  private layoutAdded: Subject<string> = new Subject<string>();

  public sendLayoutAddedEvent(id: string): void {
    this.layoutAdded.next(id);
  }

  public getLayoutAddedEvent(): Observable<string> {
    return this.layoutAdded.asObservable();
  }

  constructor(private http: HttpClient) {
  }

  public getPageById(id: number): Observable<Page> {
    return this.http.get<Page>(`${environment.api.url}/ui/v1/page/by-id/${id}`).pipe(
      page => this.currentPage$ = page
    );
  }

  public getPageBySlug(slug: string): Observable<Page> {
    return this.http.get<Page>(`${environment.api.url}/ui/v1/page/${slug}`);
  }

  public getPages(): Observable<PagesListResponse> {
    return this.http.get<PagesListResponse>(`${environment.api.url}/ui/v1/pages`);
  }

  public delete(id: number): Observable<Page> {
    return this.http.delete<Page>(`${environment.api.url}/ui/v1/page/${id}`);
  }

  public save(id: number, page: Page): Observable<Page> {
    return this.http.post<Page>(
      `${environment.api.url}/ui/v1/page/${id}`,
      page
    )
  }

  public duplicate(id: number): Observable<Page> {
    return this.http.post<Page>(
      `${environment.api.url}/ui/v1/page/${id}/duplicate`,
      {}
    )
  }

  public movePageToFolder(pageId: number, folderId: number): Observable<Page> {
    return this.http.patch<Page>(
      `${environment.api.url}/v1/pages/${pageId}/move-to-folder/${folderId}`,
      {}
    )
  }
}
